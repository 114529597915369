<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="clickBtnPopupClose"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <template>
          <h2 class="tit-h2 mt-3 d-flex">상세내용</h2>
          <div class="box-ct">
            <div class="gray-box mt-3">
              <div>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="회사구분"
                    disabled
                    v-model="txtBoxData.bizV"
                >
                </v-text-field>
                <v-text-field
                    v-model:disabled="txtBoxData.dateRange"
                    outlined
                    hide-details
                    label="게시기간"
                    disabled
                    class="form-inp icon-calendar ml-2"
                    append-icon="svg-calendar"
                ></v-text-field>
                <v-select
                  class="form-inp ml-2"
                  :items="BRD_QKTY"
                  item-value="CD"
                  item-text="CD_NM"
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="BRD_NEWQKTY"
                  disabled
                >
                  <template v-slot:label>
                    게시 유형
                  </template>
                </v-select>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="제목"
                    disabled
                    v-model="txtBoxData.titleV"
                >
                </v-text-field>
              </div>
              <div>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="작성자"
                    disabled
                    v-model="txtBoxData.wrtUserV"
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="작성일시"
                    disabled
                    v-model="txtBoxData.wrtDateV"
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="수정자"
                    disabled
                    v-model="txtBoxData.modUserV"
                >
                </v-text-field>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="수정일시"
                    disabled
                    v-model="txtBoxData.modDateV"
                >
                </v-text-field>
              </div>
            </div>
            <div class="mt-3">
              <ckeditor
                  v-model="txtAreaV"
                  :read-only="true"
                  :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <h2 class="tit-h2 mt-3 d-flex">파일목록
<!--            <div class="ml-auto align-self-center">-->
<!--              <v-btn outlined small class="btn-default" >파일등록</v-btn>-->
<!--              <v-btn outlined small class="btn-etc ml-2" >파일삭제</v-btn>-->
<!--            </div>-->
          </h2>
          <div class="box-ct mt-3">
            <v-data-table
              class="grid-default"
              :headers="gridDataHeaders"
              :items="gridDataText"
              v-model="gridListSelected"
              item-key="index"
              hide-default-footer
              calculate-widths
              show-select
              dense
            >
              <template v-slot:item.DOWNLOAD = {item}>
                <div>
                  <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="small btn-default mt-1 mb-1" @click="fileDownload(item)">다운로드</v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
          <!-- 새글 쓰기 모달창 -->
          <div class="ml-auto">
            <v-dialog v-if="dialogM6102P01" persistent v-model="dialogM6102P01" content-class="square-modal"
              max-width="1400px">
              <dialog-M6102P01
                  :headerTitle="popupTitle"
                  @hide="hideDialog('M6102P01')"
                  @Qsave="hideDialog('M6102P01')"
                  :param="M6102P01Param"
              >
              </dialog-M6102P01>
              <template v-slot:body>
              </template>
            </v-dialog>
          </div>
        </template>
      </div>
      <div class="text-right mt-3">
         <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined  class="btn-etc" @click="clickBtnDel"
        >삭제</v-btn>
<!--        <v-btn outlined  class="btn-default ml-2" disabled @click=""-->
<!--        >답변</v-btn>-->
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined  class="btn-default ml-2" @click="clickBtnModify"
        >수정</v-btn>

      </div>
    </div>
  </div>
</template>

<script>

//import Ckeditor from "@/components/Ckeditor";
import {mixin} from "../../mixin/mixin";
import DialogM6102P01 from "@/views/page/M6102P01";

export default {
  name: "MENU_M6102P02", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
  },
  components: {
    DialogM6102P01,
  //  Ckeditor,
  },
  mixins:[mixin],

  data: () => ({
    dialogM6102P01: false,
    M6102P01Param: {},

    brdNo       : '',
    brdRmkNo    : '',

    objDetails: {},

    txtBoxData : {
      bizV  : '',
      wrtUserV : '',
      wrtDateV : '',
      modUserV : '',
      modDateV : '',
      titleV   : '',
      dateRange: '',
    },
    txtAreaV : '',

    artMsg : {
      delChk: '현 게시물을 삭제 할 경우 댓글 및 댓글까지 전부 조회되지 않습니다. 계속 진행하시겠습니까?'
    },

    editorConfig: {
      // toolbar: [
      //   ["Source"],
      //   ["Styles", "Format", "Font", "FontSize"],
      //   ["Bold", "Italic"],
      //   ["Undo", "Redo"],
      //   ["About"],
      // ],
      removeButtons: "About",
      width: "100%",
      height: "250px",
      // height: "500px",
    },

    popupTitle : '',
    footerHideTitle: "닫기",

    //첨부 파일 헤더
    gridDataHeaders: [
      { text: "No",       value: "index",   width: "100px",  align: "center", sortable: false,},
      { text: "파일명",    value: "ORIGINAL_FILENAME",         align: "center",  sortable: false,},
      { text: "크기",      value: "FILE_SIZE", width: "200px", align: "center", sortable: false,},
      { text: "다운로드",   value: "DOWNLOAD", width: "200px", align: "center", sortable: false,},
      { text: "다운로드수", value: "DNLOD_CNT", width: "200px", align: "center", sortable: false,},
    ],
    gridDataText    : [], //첨부파일 리스트
    gridListSelected: [], //파일업로드 팝업 변수

    BRD_QKTY   : [],
    BRD_NEWQKTY: '1',

    alertMsg: {
      selBoxBizChk : '회사구분 은(는) 필수입력입니다.',
      noCntData    : '조회된 데이터가 없습니다.',
      newWriteChk  : '조회 후 작성 가능합니다.',
      delChk       : '현 게시물을 삭제 할 경우 댓글 및 댓글까 전부 조회되지 않습니다. 계속 진행하시겠습니까?',
      selBoxIdChk  : '게시판명 은(는) 필수입력입니다.',
      responseError: '시스템에 오류가 발생하였습니다.',
    },
  }),
  methods: {

    //**********************************
    //      게시판 팝업 상세보기 호출
    //**********************************
    async getBrdDetails(selTY) {
      let URL_SET = ''
      if (selTY == 'Y'){
        URL_SET = '/api/board/detail-popup/inqire'
      } else if (selTY == 'N'){
        URL_SET = '/api/board/detail-popup/selectNo-noChange/inqire'
      }
      let requestData = {
        headers: {},
        sendData: {}
      }

      requestData.headers["URL"]    = "/api/board/detail-popup/inqire";
      requestData.headers["SERVICE"]= "board.detail-popup";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"]  = false;
      requestData.headers["TYPE"]   = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"]= this.param.item.BRD_ID;
      requestData.sendData["BRD_NO"]= this.param.item.BRD_NO;
      requestData.sendData["ASP_NEWCUST_KEY"]= this.param.bizCd;

      let response = await this.common_postCall(requestData);
      this.getBrdDetailsThen(response);
    },

    //***************************************
    //  게시판 팝업 상세보기 호출 callBack 처리
    //***************************************
    async getBrdDetailsThen(response) {
      let resHeader = response.HEADER;
      let resData = response.DATA.at(0);
      let tempDate = resData.BULTN_FR_DT + " ~ " + resData.BULTN_TO_DT;

      if(resHeader.ERROR_FLAG==true) {
        this.common_alert(this.alertMsg.responseError, 'error')
      }

      this.txtBoxData.bizV  = this.param.item.ASP_NEWCUST_KEY;
      this.txtBoxData.dateRange = tempDate//.replaceAll('/', '-');
      this.txtBoxData.wrtUserV = resData.FST_USER_NM;
      this.txtBoxData.wrtDateV = this.parseDateTime(resData.FST_BRD_DT);
      this.txtBoxData.modUserV = resData.LAST_USER_NM;
      this.txtBoxData.modDateV = this.parseDateTime(resData.LAST_BRD_DT);
      this.txtBoxData.titleV= resData.BRD_TIT;
      this.txtAreaV = this.restoreXSS_CKeditor(resData.BRD_RMK);

      // 첨부파일 dataList 호출
      let resList = await this.mixin_getFileList(resData.FILE_GROUP_KEY);
      this.setFileList(resList)
    },

    //*********************************
    //        첨부파일 리스트 set
    //*********************************,
    setFileList(listData) {
      this.gridDataText = [];

      for(let i in listData) {
        let tempItem = listData[i];
        tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
        tempItem["index"] = ++i;

        this.gridDataText.push(tempItem);
      }
    },

    //*********************************
    //        첨부파일 다운로드
    //*********************************
    async fileDownload(item){
      let busiType = "bbs";   //palette, phone, chat, km, bbs
      let pathType = "files"; //publics,files,images,videos,inqrytypeimage

      await this.mixin_fileDownload(item, busiType, pathType)
    },


    //***************************************
    //         삭제버튼 모달 이벤트
    //***************************************
    clickBtnDel() {
      this.common_confirm(this.artMsg.delChk, this.clickDelY, null,
                                              this.clickDelN, null, "error");
    },

    async clickDelY() {
      let requestData = {
        headers : {},
        sendData: {}
      }

      requestData.headers["URL"]    = "/api/board/detail-popup/delete";
      requestData.headers["SERVICE"]= "board.detail-popup";
      requestData.headers["METHOD"] = "delete";
      requestData.headers["ASYNC"]  = false;
      requestData.headers["TYPE"]   = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"]         = this.param.item.BRD_ID;
      requestData.sendData["BRD_NO"]         = this.param.item.BRD_NO;
      requestData.sendData["PROC_ID"]        = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData["ASP_NEWCUST_KEY"]= this.param.bizCd;

      let response = await this.common_postCall(requestData);

      if (response.HEADER.ERROR_FLAG == false) {
        this.common_alert("게시물이 삭제되었습니다.", "done");
      }
      this.clickBtnPopupClose();
    },

    clickDelN() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    //***************************************
    //           수정 Button 팝업
    //***************************************
    clickBtnModify() {
      this.popupTitle = "글 수정"
      this.M6102P01Param = {
        flag     : 'modify',
        userId   : this.$store.getters['userStore/GE_USER_ID'],
        bizCd    : this.param.bizCd,
        bizNm    : this.param.bizNm,
        item     : this.param.item,
        txtArea  : this.txtAreaV,
      };
      this.showDialog('M6102P01')
    },

    clickBtnPopupClose() {
      this.$emit('hide')
    },

    showDialog(menu) {
      this[`dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`dialog${menu}`] = false;
      this.getBrdDetails('N');
    },

    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join('/');
      let hour = [data.substr(8, 2), data.substr(10, 2), data.substr(12, 2)].join(':');

      return year+' '+hour;
    }

  },

  computed: {
  },

  created() {
  },

  async mounted() {
    this.txtBoxData.bizV  = this.param.bizCd;
    this.txtBoxData.wrtUserV = this.param.userId;

    this.getBrdDetails('Y');

    let codeName = ["BRD001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.BRD_QKTY = [...this.BRD_QKTY, ...common_code[0].group_value];
  },
};

</script>

<style></style>
