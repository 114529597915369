<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="popupClose"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <template>
          <h2 class="tit-h2 mt-3 d-flex">상세내용</h2>
          <div class="box-ct mt-3">
            <div class="gray-box">
              <div>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="회사구분"
                    disabled
                    v-model="txtBoxBizV"
                >
                </v-text-field>
                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRangeText"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        outlined
                        hide-details
                        label="게시기간"
                        class="form-inp icon-calendar ml-2"
                        append-icon="svg-calendar"
                    ></v-text-field>
                  </template>
                  <div class="datepicker-range">
                    <v-date-picker
                        v-model="dates[0]"
                        no-title
                        dark
                        color="#F7AFAE"
                        locale="ko-KR"
                        @change="startDate"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                    ></v-date-picker>
                    <v-date-picker
                        v-model="dates[1]"
                        no-title
                        dark
                        color="#F7AFAE"
                        locale="ko-KR"
                        @change="endDate"
                        show-current="false"
                        :min="dates[0]"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                    ></v-date-picker>
                  </div>
                  <div class="text-center pt-3 pb-3">
                    <v-btn
                        outlined
                        class="btn-default"
                        @click="menu1 = false"
                    >확인</v-btn
                    >
                  </div>
                </v-menu>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    label="작성자"
                    disabled
                    v-model="txtBoxUserV"
                >
                </v-text-field>
                <v-select
                  class="form-inp ml-2"
                  :items="BRD_QKTY"
                  item-value="CD"
                  item-text="CD_NM"
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="BRD_NEWQKTY"
                >
                  <template v-slot:label>
                    게시 유형
                  </template>
                </v-select>
                <v-text-field
                    class="form-inp ml-2"
                    outlined
                    hide-details
                    v-model="txtBoxTitleV"
                >
                <template v-slot:label>
                  제목
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <ckeditor v-model="txtAreaV" :config="editorConfig"></ckeditor>
          </div>
          <h2 class="tit-h2 mt-3 d-flex">파일목록
            <div class="ml-auto align-self-center">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegiFile')" outlined  class="btn-default" @click="clickAddFiles">파일등록</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelFile')" outlined  class="btn-etc ml-2" @click="clickDelFiles">파일삭제</v-btn>
              <!-- 파일 업로드 모달창 -->
              <v-dialog max-width="655" persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone" content-class="square-modal min-auto"
              >
                <dialog-VueDropzone
                    headerTitle="첨부파일 등록"
                    :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
                    :fileGroupKey    = this.dropZoneParam.fileGroupKey
                    :pathType        = this.dropZoneParam.pathType
                    :busiType        = this.dropZoneParam.busiType
                    :acceptedFiles   = this.dropZoneParam.acceptedFiles
                    :userOptions     = this.dropZoneParam.userOptions
                    @hide="hideDialog('VueDropzone')"
                    @submit="uploadFileResponse"
                >
                </dialog-VueDropzone>
              </v-dialog>
              <!-- 파일 업로드 모달창 -->
            </div>
          </h2>
          <div class="box-ct mt-3">
            <v-data-table
              class="grid-default"
              :headers="gridDataHeaders"
              :items="gridDataText"
              v-model="gridListSelected"
              item-key="index"
              hide-default-footer
              calculate-widths
              show-select
              dense
            >
              <template v-slot:item.DOWNLOAD = {item}>
                <div>
                  <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="small btn-default mt-1 mb-1" @click="fileDownload(item)">다운로드</v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </template>
      </div>
      <div class="text-right mt-3">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-default ml-2" @click="clickBtnSave"
          >저장</v-btn>
      </div>
    </div>
  </div>
</template>

<script>

//import Ckeditor from "@/components/Ckeditor";
import {mixin} from "../../mixin/mixin";
import vue2Dropzone from "vue2-dropzone";
import DialogFileUploadPop from "@/components/FileUploadPop";
import DialogVueDropzone from "@/components/VueDropzone";

export default {
  name: "MENU_M6102P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    DialogFileUploadPop,
    DialogVueDropzone,
  //  Ckeditor,
  },
  mixins:[mixin],

  data: () => ({
    brdNo       : '',
    brdRmkNo    : '',
    txtBoxBizV  : '',
    txtBoxUserV : '',
    txtBoxTitleV: '',
    txtAreaV    : '',

    flagData    : {
      inputFlag : '',
      replyYn   : '',
      lvlNo     : '',
      upperBrdNo: '',
    },

    editorConfig: {
      removeButtons: "About",
      width: "100%",
      height: "280px",
    },

    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
    ],

    menu1: false,
    footerHideTitle: "닫기",

    // 첨부파일 그리드 헤더
    gridDataHeaders: [
      { text: "No",       value: "index",   width: "100px",  align: "center", sortable: false,},
      { text: "파일명",    value: "ORIGINAL_FILENAME",         align: "center",  sortable: false,},
      { text: "크기",      value: "FILE_SIZE", width: "200px", align: "center", sortable: false,},
      { text: "다운로드",   value: "DOWNLOAD", width: "200px", align: "center", sortable: false,},
      { text: "다운로드수", value: "DNLOD_CNT", width: "200px", align: "center", sortable: false,},
    ],

    gridDataText: [], //첨부파일 리스트
    gridListSelected: [], //파일업로드 팝업 변수

    // 첨부파일 팝업 param, boolean
    dropZoneParam: {},
    dialogVueDropzone: false,

    // etc
    ASP_NEWCUST_KEY: "",
    FILE_GROUP_KEY : "",

    BRD_QKTY:[],
    BRD_NEWQKTY: '1',

    alertMsg: {
      selBoxBizChk : '회사구분 은(는) 필수입력입니다.',
      noCntData    : '조회된 데이터가 없습니다.',
      newWriteChk  : '조회 후 작성 가능합니다.',
      delChk       : '현 게시물을 삭제 할 경우 댓글 및 댓글까 전부 조회되지 않습니다. 계속 진행하시겠습니까?',
      selBoxIdChk  : '게시판명 은(는) 필수입력입니다.',
      responseError: '시스템에 오류가 발생하였습니다.',
    },
  }),
  methods: {

    //***************************************
    //             게시글 저장
    //***************************************
    // 저장 버튼 clickEvent
    clickBtnSave() {
      if(this.txtBoxTitleV=='' || this.txtBoxTitleV==null) {
        this.common_alert("제목 은(는) 필수입력입니다.", "error");
        return;
      }
      this.getBrdRmkNo();

    },

    // 데이터 저장
    setSaveData() {
      let requestData = {
        headers: {},
        sendData: {}
      }

      requestData.headers["URL"]       = "/api/board/common/ntt-atchmnfl/upload";
      requestData.headers["SERVICE"]   = "board.common.ntt-atchmnfl";
      requestData.headers["METHOD"]    = "upload";
      requestData.headers["ASYNC"]     = "true";
      requestData.headers["CALL_BACK"] = "BoardProcessPopup.processRtn_callback";
      requestData.headers["TYPE"]      = "BIZ_SERVICE";

      requestData.sendData["ASP_NEWCUST_KEY"] = this.param.bizCd;
      requestData.sendData["BULTN_FR_DT"]     = this.dates[0];
      requestData.sendData["BULTN_TO_DT"]     = this.dates[1];
      requestData.sendData["FST_USER_NM"]     = this.txtBoxUserV;
      requestData.sendData["BRD_TIT"]         = this.txtBoxTitleV;
      requestData.sendData["BRD_RMK"]         = this.restoreXSS_CKeditorDec(this.txtAreaV);
      requestData.sendData["BRD_ID"]          = this.param.flag == "new" ? this.param.BRD_ID : this.param.item.BRD_ID;
      requestData.sendData["BRD_NO"]          = this.brdNo;
      requestData.sendData["UPPER_BRD_NO"]    = this.flagData.upperBrdNo;
      requestData.sendData["LVL_NO"]          = this.flagData.lvlNo;
      requestData.sendData["REPLE_YN"]        = this.flagData.replyYn;
      requestData.sendData["USE_YN"]          = "Y";
      requestData.sendData["BRD_RMK_NO"]      = this.brdRmkNo;
      requestData.sendData["USER_ID"]         = this.txtBoxUserV;
      requestData.sendData["PROC_ID"]         = this.txtBoxUserV;
      requestData.sendData["WRTR_DRPT_CD"]    = "x";
      requestData.sendData["ACCESS_IP"]       = this.$store.getters["userStore/GE_USER_ROLE"].cnnctIp;
      requestData.sendData["DATA_FLAG"]       = this.flagData.inputFlag;
      requestData.sendData["FILE_GROUP_KEY"]  = this.FILE_GROUP_KEY;
      requestData.sendData["BRD_QUICK_TY"]    = this.BRD_NEWQKTY;

      this.clickBtnSaveThen(requestData);
    },

    // 데이터 저장 callBack
    async clickBtnSaveThen(requestData) {
      let response = await this.common_postCall(requestData);
      let resHeader = response.HEADER;
      let resData = response.DATA;

      if(resHeader.ERROR_FLAG==false) {
        this.common_alert("게시물이 등록 또는 수정되었습니다.", "done");
        this.popupClose();
        return;
      } else {
        this.common_alert(this.alertMsg.responseError, 'error')
        return;
      }
      //this.$emit('submit');
    },


    //*********************************************
    //    수정 Btn 으로 팝업 오픈 시 상세정보 가저오기
    //*********************************************
    async getBrdDetail() {
      let requestData = {
        headers: {},
        sendData: {}
      }

      requestData.headers["URL"]     = "/api/board/detail-popup/selectNo-noChange/inqire";
      requestData.headers["SERVICE"] = "board.detail-popup.selectNo-noChange";
      requestData.headers["METHOD"]  = "inqire";
      requestData.headers["ASYNC"]   = false;
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["BRD_ID"]          = this.param.item.BRD_ID;
      requestData.sendData["BRD_NO"]          = this.param.item.BRD_NO;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.param.bizCd;

      let response = await this.common_postCall(requestData);
      this.getBrdDetailThen(response);

      requestData = {};
    },

    //**********************************
    //     상세정보 가져오기 callBack
    //**********************************
    async getBrdDetailThen(response) {
      let resHeader = response.HEADER;
      let resData = response.DATA;

      if(resHeader.ERROR_FLAG==true) {
        this.common_alert(this.alertMsg.responseError, 'error')
      }

      this.txtBoxbizV   = this.param.item.ASP_NEWCUST_KEY;
      this.txtBoxUserV  = this.param.item.FST_USER_NM;
      this.txtBoxTitleV = resData.at().BRD_TIT;
      this.txtAreaV     = this.restoreXSS_CKeditor(resData.at().BRD_RMK);

      this.FILE_GROUP_KEY = resData[0].FILE_GROUP_KEY;
      let resList = await this.mixin_getFileList(resData[0].FILE_GROUP_KEY);
      this.setFileList(resList)
    },

    //*********************************
    //        첨부파일 업로드
    //*********************************
    // 파일첨부 버튼 클릭
    clickAddFiles() {
      let busiType = "bbs";   //palette, phone, chat, km, bbs
      let pathType = "files"; //publics,files,images,videos,inqrytypeimage

      this.dropZoneParam = {
        ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
        fileGroupKey : this.FILE_GROUP_KEY,
        pathType : pathType, //publics,files,images,videos,inqrytypeimage
        busiType : busiType, //palette, phone, chat, km, bbs
        acceptedFiles : ".png,.jpg,.gif,.jpeg,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt",
        userOptions :{
          parallelUploads: 10,
          //url : process.env.VUE_APP_API_URL + "api/file/"+ busiType +"/"+ pathType +"/uploads",
          //url : "http://local.hkpalette.com:6001/" + "api/file/"+ busiType +"/"+ pathType +"/uploads",
        }
      };
      this.showDialog('VueDropzone');
    },

    // 첨부파일 팝업 업로드 후
    async uploadFileResponse(data){
      this.hideDialog('VueDropzone');
      this.FILE_GROUP_KEY = data[0].fileGroupKey;

      let resList = await this.mixin_getFileList(data[0].fileGroupKey);
      this.setFileList(resList)
    },

    //*********************************
    //        첨부파일 다운로드
    //*********************************
    async fileDownload(item){
      let busiType = "bbs";   //palette, phone, chat, km, bbs
      let pathType = "files"; //publics,files,images,videos,inqrytypeimage

      await this.mixin_fileDownload(item, busiType, pathType)
    },

    //*********************************
    //          첨부파일 삭제
    //*********************************,
    clickDelFiles(){
      console.log(this.gridListSelected);
      if(this.gridListSelected.length != 0) {
        this.common_confirm('선택한 파일을 삭제하시겠습니까?',
            this.confirmDelYes, null, this.confirmDelNo, null, 'error');
      }
    },

    async confirmDelYes() {
      let rtnBln = await this.deleteFilesMulti(this.gridListSelected);

      if (rtnBln) {
        // 첨부파일 dataList 호출
        let resList = await this.mixin_getFileList(this.gridListSelected[0].FILE_GROUP_KEY);
        this.setFileList(resList)
      }
    },

    confirmDelNo() {
      return;
    },

    //*********************************
    //        첨부파일 리스트 set
    //*********************************,
    setFileList(listData) {
      this.gridDataText = [];

      for(let i in listData) {
        let tempItem = listData[i];
        tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
        tempItem["index"] = ++i;

        this.gridDataText.push(tempItem);
      }
    },

    //***********************************************
    //    신규 Btn 으로 팝업창 오픈시 brdNo 받기
    //***********************************************
    async getBrdNo() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
      requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
      requestData.headers["METHOD"]  = "inqry";
      requestData.headers["ASYNC"]   = "false";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["BIZ_CASE"] = "BRD";

      let response = await this.common_postCall(requestData);
      this.brdNo = response.DATA.at(0).RET_VAL;
    },

    //***************************************
    //       게시글 저장용 brdRmkNo 받기
    //***************************************
    async getBrdRmkNo() {
      let requestData = {
        headers: {},
        sendData: {}
      };

      requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
      requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
      requestData.headers["METHOD"]  = "inqry";
      requestData.headers["ASYNC"]   = "false";
      requestData.headers["TYPE"]    = "BIZ_SERVICE";

      requestData.sendData["BIZ_CASE"] = "TWB";
      let response = await this.common_postCall(requestData);
      this.brdRmkNo = response.DATA.at(0).RET_VAL;

      await this.setSaveData();
    },

    //*************************************
    //        팝업창 오픈 시 유형 체크
    //*************************************
    checkFlag(flag) {
      switch (flag) {
        case 'new':
          this.flagData.inputFlag = 'I';
          this.flagData.replyYn   = '0';
          this.flagData.lvlNo     = '-1';
          this.flagData.upperBrdNo= '-1';
          this.getBrdNo();
          break;

        case 'modify':
          this.flagData.inputFlag = 'U';
          this.flagData.replyYn   = '0';
          this.flagData.lvlNo     = '-1';
          this.flagData.upperBrdNo= '-1';
          this.brdNo = this.param.item.BRD_NO;
          this.getBrdDetail();
          break;

        default:
          break;
      }
    },

    //*********************************
    //           etc method
    //*********************************,
    showDialog(menu) {
      this[`dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`dialog${menu}`] = false;
    },

    popupClose() {
      this.$emit('hide')
    },

    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

  },

  created() {
  },

  async mounted() {
    this.txtBoxBizV  = this.param.bizNm;
    this.txtBoxUserV = this.param.userId;
    this.ASP_NEWCUST_KEY = this.param.bizCd;

    this.checkFlag(this.param.flag);

    let codeName = ["BRD001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.BRD_QKTY = [...this.BRD_QKTY, ...common_code[0].group_value];
  },

  computed: {
    dateRangeText() {
      let newStartDate = this.dates[0].replaceAll('-', "/");
      let newEndDate = this.dates[1].replaceAll('-', "/");;
      return newStartDate + " ~ " + newEndDate;
    },
  },
};

</script>

<style></style>
